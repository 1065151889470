import DashboardLayout from "../../layouts/dashboard";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate, useParams } from "react-router-dom";

import SeatPicker from "react-seat-picker";
import "./Seats.css";
import { useFormik } from "formik";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import Loader from "../../components/Loader";
import Button from "@mui/material/Button";

import Card from "@mui/material/Card";
import { getEmployeeForDropDown } from "../../redux/employee/employeeThunk";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import { encryption, decryption } from "../../utils/encodeString";
import { toast } from "react-toastify";
import {
  createSeatNumberAllocation,
  getAllSeatNumberAllocation,
  resetSeatNumber,
} from "../../redux/seatArrangment/seatThunk";
import seatSchema from "../../validations/seatSchema";
import { Modal } from "@mui/material";
import ExcelJS from "exceljs";
import moment from "moment";
import { saveAs } from "file-saver";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function SeatAllocation() {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);
  const [a, setA] = useState();
  let navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [newRows, setNewRows] = useState([]);
  const [newSpecialRows, setNewSpecialRows] = useState([])
  // const [newRows1, setNewRows1] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const[assignSeat,setAssignSeat] = React.useState([])
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const [noteError, setNoteError] = useState({ flag: false, title: "" });
  const [finalEmployees, setFinalEmployees] = useState([]);
  const [exportData, setExportData] = useState([]);

  const rows = [
    [
      { id: 1, number: "A0", orientation: "center" },
      //{ id: 1, number: "A0", orientation: "center" },
      null,
      null,
      { id: 2, number: "C0", orientation: "center" },
      //{ id: 2, number: "C0", orientation: "center" },
      null,
      null,
      null,
      null,
      null,
      null,
      { id: 3, number: "E0", orientation: "west" },
      null,
      { id: 4, number: "F0", orientation: "center" },
      null,
      null,
      { id: 5, number: "H0", orientation: "center" },
      null,
      null,
      { id: 6, number: "J0", orientation: "center" },
      null,
      null,
      { id: 7, number: "L0", orientation: "east" },
      null,
      null,
      null,
      null,
      null,
      { id: 21, number: "M1", orientation: "north" },
      null,
      { id: 22, number: "M2", orientation: "north" },
      null,
      { id: 23, number: "M3", orientation: "north" },
      null,
      { id: 24, number: "M4", orientation: "north" },
      null,
      { id: 25, number: "M5", orientation: "north" },
      null,
      { id: 26, number: "M6", orientation: "north" },
      null,
    ],
    [
      { id: 8, number: "A1", orientation: "east" },
      { id: 9, number: "B1", orientation: "west" },
      null,
      { id: 10, number: "C1", orientation: "east" },
      { id: 11, number: "D1", orientation: "west" },
      null,
      null,
      null,
      null,
      null,
      { id: 12, number: "E1", orientation: "west" },
      null,
      { id: 13, number: "F1", orientation: "east" },
      { id: 14, number: "G1", orientation: "west" },
      null,
      { id: 15, number: "H1", orientation: "east" },
      { id: 16, number: "I1", orientation: "west" },
      null,
      { id: 18, number: "J1", orientation: "east" },
      { id: 19, number: "K1", orientation: "west" },
      null,
      { id: 20, number: "L1", orientation: "east" },
      null,
      null,
      null,
      null,
      null,
      { id: 39, number: "N1", orientation: "south" },
      null,
      { id: 40, number: "N2", orientation: "south" },
      null,
      { id: 41, number: "N3", orientation: "south" },
      null,
      { id: 42, number: "N4", orientation: "south" },
      null,
      { id: 43, number: "N5", orientation: "south" },
      null,
      { id: 44, number: "N6", orientation: "south" },
    ],
    [
      null,
      { id: 28, number: "A2", orientation: "east" },
      { id: 8, number: "B2", orientation: "west" },
      null,
      { id: 29, number: "C2", orientation: "east" },
      { id: 30, number: "D2", orientation: "west" },
      null,
      null,
      null,
      null,
      null,
      { id: 31, number: "E2", orientation: "west" },
      null,
      { id: 32, number: "F2", orientation: "east" },
      { id: 33, number: "G2", orientation: "west" },
      null,
      { id: 34, number: "H2", orientation: "east" },
      { id: 35, number: "I2", orientation: "west" },
      null,
      { id: 36, number: "J2", orientation: "east" },
      { id: 37, number: "K2", orientation: "west" },
      null,
      { id: 38, number: "L2", orientation: "east" },
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    [
      { id: 45, number: "A3", orientation: "east" },
      { id: 46, number: "B3", orientation: "west" },
      null,
      { id: 47, number: "C3", orientation: "east" },
      { id: 48, number: "D3", orientation: "west" },
      null,
      null,
      null,
      null,
      null,
      { id: 49, number: "E3", orientation: "west" },
      null,
      { id: 50, number: "F3", orientation: "east" },
      { id: 51, number: "G3", orientation: "west" },
      null,
      { id: 52, number: "H3", orientation: "east" },
      { id: 53, number: "I3", orientation: "west" },
      null,
      { id: 54, number: "J3", orientation: "east" },
      { id: 55, number: "K3", orientation: "west" },
      null,
      { id: 56, number: "L3", orientation: "east" },
      null,
      null,
      null,
      null,
      null,
      { id: 69, number: "O1", orientation: "north" },
      null,
      { id: 70, number: "O2", orientation: "north" },
      null,
      { id: 71, number: "O3", orientation: "north" },
      null,
      { id: 72, number: "O4", orientation: "north" },
      null,
      { id: 73, number: "O5", orientation: "north" },
      null,
      { id: 74, number: "O6", orientation: "north" },
    ],
    [
      { id: 57, number: "A4", orientation: "east" },
      { id: 58, number: "B4", orientation: "west" },
      null,
      { id: 59, number: "C4", orientation: "east" },
      { id: 60, number: "D4", orientation: "west" },
      null,
      null,
      null,
      null,
      null,
      { id: 61, number: "E4", orientation: "west" },
      null,
      { id: 62, number: "F4", orientation: "east" },
      { id: 63, number: "G4", orientation: "west" },
      null,
      { id: 64, number: "H4", orientation: "east" },
      { id: 65, number: "I4", orientation: "west" },
      null,
      { id: 66, number: "J4", orientation: "east" },
      { id: 67, number: "K4", orientation: "west" },
      null,
      { id: 68, number: "L4", orientation: "east" },
      null,
      null,
      null,
      null,
      null,
      { id: 87, number: "P1", orientation: "south" },
      null,
      { id: 88, number: "P2", orientation: "south" },
      null,
      { id: 89, number: "P3", orientation: "south" },
      null,
      { id: 90, number: "P4", orientation: "south" },
      null,
      { id: 91, number: "P5", orientation: "south" },
      null,
      { id: 92, number: "P6", orientation: "south" },
      null,
    ],
    [
      null,
      { id: 75, number: "A5", orientation: "east" },
      { id: 76, number: "B5", orientation: "west" },
      null,
      { id: 77, number: "C5", orientation: "east" },
      { id: 78, number: "D5", orientation: "west" },
      null,
      null,
      null,
      null,
      null,
      { id: 79, number: "E5", orientation: "west" },
      null,
      { id: 80, number: "F5", orientation: "east" },
      { id: 81, number: "G5", orientation: "west" },
      null,
      { id: 82, number: "H5", orientation: "east" },
      { id: 83, number: "I5", orientation: "west" },
      null,
      { id: 84, number: "J5", orientation: "east" },
      { id: 85, number: "K5", orientation: "west" },
      null,
      { id: 86, number: "L5", orientation: "east" },
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    [
      { id: 93, number: "A6", orientation: "east" },
      { id: 94, number: "B6", orientation: "west" },
      null,
      { id: 95, number: "C6", orientation: "east" },
      { id: 96, number: "D6", orientation: "west" },
      null,
      null,
      null,
      null,
      null,
      { id: 99, number: "E6", orientation: "west" },
      null,
      { id: 100, number: "F6", orientation: "east" },
      { id: 101, number: "G6", orientation: "west" },
      null,
      { id: 102, number: "H6", orientation: "east" },
      { id: 103, number: "I6", orientation: "west" },
      null,
      { id: 104, number: "J6", orientation: "east" },
      { id: 105, number: "K6", orientation: "west" },
      null,
      { id: 106, number: "L6", orientation: "east" },
      null,
      null,
      null,
      null,
      null,
      { id: 119, number: "Q1", orientation: "north" },
      null,
      { id: 120, number: "Q2", orientation: "north" },
      null,
      { id: 121, number: "Q3", orientation: "north" },
      null,
      { id: 122, number: "Q4", orientation: "north" },
      null,
      { id: 123, number: "Q5", orientation: "north" },
      null,
      { id: 124, number: "Q6", orientation: "north" },
    ],
    [
      { id: 107, number: "A7", orientation: "east" },
      { id: 108, number: "B7", orientation: "west" },
      null,
      { id: 109, number: "C7", orientation: "east" },
      { id: 110, number: "D7", orientation: "west" },
      null,
      null,
      null,
      null,
      null,
      { id: 111, number: "E7", orientation: "west" },
      null,
      { id: 112, number: "F7", orientation: "east" },
      { id: 113, number: "G7", orientation: "west" },
      null,
      { id: 114, number: "H7", orientation: "east" },
      { id: 115, number: "I7", orientation: "west" },
      null,
      null,
      // { id: 116, number: "J7", orientation: "east" },
      { id: 117, number: "K7", orientation: "west" },
      null,
      { id: 118, number: "L7", orientation: "east" },
      null,
      null,
      null,
      null,
      null,
      { id: 129, number: "R1", orientation: "south" },
      null,
      { id: 130, number: "R2", orientation: "south" },
      null,
      { id: 131, number: "R3", orientation: "south" },
      null,
      { id: 132, number: "R4", orientation: "south" },
      null,
      { id: 133, number: "R5", orientation: "south" },
      null,
      { id: 134, number: "R6", orientation: "south" },
    ],
    [
      null,
      { id: 125, number: "A8", orientation: "east" },
      { id: 126, number: "B8", orientation: "west" },
      null,
      { id: 127, number: "C8", orientation: "east" },
      { id: 128, number: "D8", orientation: "west" },
      null,
      null,
      null,
      null,
      null,
      null,
     
    ],
    [
      null,
      { id: 134, number: "A9", orientation: "east" },
      { id: 135, number: "B9", orientation: "west" },
      null,
      { id: 136, number: "C9", orientation: "east" },
      { id: 137, number: "D9", orientation: "west" },
      null,
      null,
      null,
      null,
    ],
  ];

  const specialRows=[
    [
      { id: 138, number: "S0", orientation: "center" },
      //{ id: 1, number: "A0", orientation: "center" },
      null,
      { id: 139, number: "S1", orientation: "center" },
      //{ id: 2, number: "C0", orientation: "center" },
      null,
      { id: 140, number: "S2", orientation: "center" },
      null,
      { id: 141, number: "S3", orientation: "center" },
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
  ]

  const formik = useFormik({
    initialValues: {
      employeeId: "",
      seatNumber: "",
    },
    validationSchema: seatSchema,
    onSubmit: async (values, { resetForm }) => {

   

      try {
        await dispatch(createSeatNumberAllocation(values)).unwrap();
        dispatch(getAllSeatNumberAllocation()).then(() => {
          setLoading(true);
          dispatch(getAllSeatNumberAllocation());
          setLoading(false);
        });
        navigate("/seatAllocation");
        // window.location.reload(false);
        resetForm();
        // setFieldValue("employeeId","")
        // setFieldValue("seatNumber", "");
      } catch (error) {
        toast.error(error.message);
      }
    
    },
  });
  const {
    handleSubmit,
    values,
    setFieldValue,
    getFieldProps,
    isSubmitting,
    handleChange,
    setValues,
    touched,
    errors,
    handleBlur,
  } = formik;

  const employeeData = useSelector((state) => state.employee.allEmployeeData);
  const data = useSelector((state) => state.seatAllocation);
  // console.log("Values",values)

  useEffect(() => {
    dispatch(getEmployeeForDropDown());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllSeatNumberAllocation());
  }, [dispatch]);

  useEffect(() => {
    if (data && data.loading === false) {
      data?.data?.map((seat) => {
        employeeData?.forEach((e) => {
          if (e.id === seat.employeeId) {
            setA(e.id);
          }
        });
      });
    }
  }, [dispatch, navigate]);

  const handleSeats = (seats) => {
    seats?.data?.map((s) => {});
  };

  const reservedSeat = async () => {
    var datas = document.getElementsByClassName("seat--reserved");
    let el = [...datas];
    // console.log("el", el);
    if (el?.length > 0) {
      // console.log("el", el);

      el?.map((ele, i) => {
        // console.log("el", el[i]);
        ele?.addEventListener("click", async (e) => {
          let seatNumber =
            e?.target?.innerText.length > 2
              ? e?.target?.innerText.slice(
                  e?.target?.innerText.length - 2,
                  e?.target?.innerText.length
                )
              : e?.target?.innerText;
          //       console.log('seatNumber: ', seatNumber);
          // console.log("E",e?.target?.innerText || e?.target?.outerText)
          // console.log('newRows: ', newRows);
          const employeeId = data?.data?.find(
            (seat) => seat?.seatNumber == seatNumber
          );
          // console.log('employeeId: ', employeeId);
          setFieldValue("seatNumber", seatNumber);
          setFieldValue("employeeId", employeeId?.id);
          //   const updatedNewRows = newRows?.map((row) => {
          //   if (Array.isArray(row)) {
          //     return row.map((seat) => {
          //       if (seat && seat.number == seatNumber) {
          //         return {
          //           id: seat?.id,
          //           number: seat?.number,
          //           orientation: seat?.orientation,
          //         };
          //       }
          //       return seat;
          //     });
          //   }
          //   return row;
          // });
          handleOpen2();
          // console.log('updatedNewRows=====>>: ', updatedNewRows);
          // await setNewRows(updatedNewRows)
          // console.log(data);
          //       let newData = {...data}
          //       console.log('newData: ', newData);
          //       const NewData = data.data.filter(item => item.seatNumber != seatNumber);

          //       newData.data = [...NewData];
          // await printArray(rows, newData);
          // console.log(newData);
        });
      });
    }
  };
  useEffect(() => {
    reservedSeat();
  }, [loading]);
  useEffect(() => {
    // if (id) {
    setLoading(true);

    const data = dispatch(getAllSeatNumberAllocation()).unwrap();
    data
      .then(async (values) => {
        await handleSeats(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
        // navigate("/college");
      });
    // }
  }, [dispatch, navigate]);

  useEffect(() => {
    // let a = [];
    let temp = employeeData;
    // employeeData?.map((emp) => {
    //   // dataItem.condition = dataItem.condition ? rulesMap[dataItem.condition] : null;
    //   data?.data?.forEach((e) => {
    //     if (e.id == emp.id) {
    //       a = [...a, emp.id];
    //     }
    //   });
    // });
    // temp = temp.filter((val) => !a.includes(val.id));
    setFinalEmployees(temp);
  }, [employeeData, data]);
  //   console.log('data: ', data);
  // console.log("FINALEMPLOYEE___", finalEmployees);
  const addSeatCallback = ({ row, number, id }, addCb) => {
    setSelected((prevItems) => [...prevItems, number]);
    const reserved = `isReserved: ${true}`;
    const newTooltip = `Seat: ${number}`;
    addCb(row, number, id, newTooltip, reserved);
    setFieldValue("seatNumber", number);
    setFieldValue("employeeId", "");
    handleOpen();
  };

  const removeSeatCallback = ({ row, number, id }, removeCb) => {
    setSelected((list) => list.filter((item) => item !== number));
    removeCb(row, number);
  };

  const printArray = async (arr, data2) => {
    // console.log('data:========> ', data2);
    await setLoading(true);
    let seats = [];
    seats = data2?.data?.map((d) => {

      return { seat: d?.seatNumber, emp: d?.firstName + " " + d?.lastName, empId : d?.id };
    });
    for (var i = 0; i < arr.length; i++) {
      for (var j = 0; j < arr[i].length; j++) {
        if (arr[i][j] !== null) {
          seats?.map((s) => {
            if (s.seat == arr[i][j]?.number) {
              arr[i][j].isReserved = true;
              arr[i][j].tooltip = s?.emp;
            }
          });
        }
      }
    }
     
    await setNewRows(arr);
    if(seats?.length > 0){
    
      await setAssignSeat(seats); 
    }

    await setLoading(false);
  };

  const printArrayForSpecialRows = async (arr, data2) => {
    // console.log('data:========> ', data2);
    await setLoading(true);
    let seats = [];
    seats = data2?.data?.map((d) => {

      return { seat: d?.seatNumber, emp: d?.firstName + " " + d?.lastName, empId : d?.id };
    });
    for (var i = 0; i < arr.length; i++) {
      for (var j = 0; j < arr[i].length; j++) {
        if (arr[i][j] !== null) {
          seats?.map((s) => {
            if (s.seat == arr[i][j]?.number) {
              arr[i][j].isReserved = true;
              arr[i][j].tooltip = s?.emp;
            }
          });
        }
      }
    }

    await setNewSpecialRows(arr);
    if(seats?.length > 0){
    
      await setAssignSeat(seats);
    }

    await setLoading(false);
  };
  useEffect(() => {
    printArray(rows, data);
    printArrayForSpecialRows(specialRows,data)
    // printArray(rows1, data);
  }, [data]);

  // console.log('NewRows: ', newRows);
  const handleAllocate = async (employeeId, seatNumber) => {
    if (employeeId == "") {
      setNoteError({ flag: true, title: "Please select employee" });
    }
    if (employeeId != "") {
      setNoteError({ flag: false, title: "" });
    }
    if (employeeId != "") {
      let findSeat = assignSeat?.find((e) => (e?.empId) == employeeId)
    
      if(findSeat?.empId && findSeat?.seat ){
        toast.error(`${findSeat?.emp} already has seat ${findSeat?.seat} !`);
      }else{
      try {
        let body = {
          employeeId: employeeId,
          seatNumber: seatNumber || values?.seatNumber,
        };
        await dispatch(createSeatNumberAllocation(body)).unwrap();
        dispatch(getAllSeatNumberAllocation()).then(() => {
          handleClose();
          setLoading(true);
          dispatch(getAllSeatNumberAllocation());
          setFieldValue("employeeId", "");
          setLoading(false);
        });
        navigate("/seatAllocation");
        // window.location.reload(true);
        // resetForm();
      } catch (error) {
        toast.error(error.message);
      }
    }
    }
  };

  const handleReAllocate = async (employeeId, seatNumber) => {

    
    if (employeeId == "") {
      setNoteError({ flag: true, title: "Please select employee" });
    }
    if (employeeId != "") {
      setNoteError({ flag: false, title: "" });
    }
    if (employeeId != "") {
      let findSeat = assignSeat?.find((e) => (e?.empId) == employeeId)
    
      if(findSeat?.empId && findSeat?.seat ){
        toast.error(`${findSeat?.emp} already has seat ${findSeat?.seat} !`);
      }else{
     try {
        let body = {
          employeeId: employeeId,
          seatNumber: seatNumber || values?.seatNumber,
        };
        await dispatch(createSeatNumberAllocation(body)).unwrap();
        dispatch(getAllSeatNumberAllocation()).then(() => {
          handleClose2();
          setLoading(true);
          dispatch(getAllSeatNumberAllocation());
          setFieldValue("employeeId", "");
          setLoading(false);
        });
        navigate("/seatAllocation");
        // window.location.reload(false);
        // resetForm();
      } catch (error) {
        toast.error(error.message);
      }
      }

     
    }
  };
  const handleCancel = () => {
    handleClose();
    setFieldValue("employeeId", "");
    setNoteError({ flag: false, title: "" });
  };
  const handleCancel2 = () => {
    handleClose2();
    setFieldValue("employeeId", "");
    setNoteError({ flag: false, title: "" });
  };
  const handleRemoveAllocate = async () => {
    let body = { seatNumber: values?.seatNumber };
    await dispatch(resetSeatNumber(body)).unwrap();
    handleClose2();
    // await setNewRows([])
    setFieldValue("employeeId", "");
    setFieldValue("seatNumber", "");
    // setLoading(true)
    const data = await dispatch(getAllSeatNumberAllocation()).unwrap();
    let newData = data || [];
    // console.log('data:========>=====>>>> ', newData);

    await printArray(rows, newData);
    await printArrayForSpecialRows(specialRows, newData);
    // await printArray(specialRows, newData); 
    // await printArray(rows1, newData);
    // navigate("/seatAllocation");
    // window.location.reload()
  };

  // if (loading) {
  // 	return <Loader />;
  // }

  let seatData = [
    "A0",
    "A1",
    "A2",
    "A3",
    "A4",
    "A5",
    "A6",
    "A7",
    "A8",
    "A9",
    "B1",
    "B2",
    "B3",
    "B4",
    "B5",
    "B6",
    "B7",
    "B8",
    "B9",
    "C0",
    "C1",
    "C2",
    "C3",
    "C4",
    "C5",
    "C6",
    "C7",
    "C8",
    "C9",
    "D1",
    "D2",
    "D3",
    "D4",
    "D5",
    "D6",
    "D7",
    "D8",
    "D9",
    "E0",
    "E1",
    "E2",
    "E3",
    "E4",
    "E5",
    "E6",
    "E7",
    "F0",
    "F1",
    "F2",
    "F3",
    "F4",
    "F5",
    "F6",
    "F7",
    "G1",
    "G2",
    "G3",
    "G4",
    "G5",
    "G6",
    "G7",
    "H0",
    "H1",
    "H2",
    "H3",
    "H4",
    "H5",
    "H6",
    "H7",
    "I1",
    "I2",
    "I3",
    "I4",
    "I5",
    "I6",
    "I7",
    "J0",
    "J1",
    "J2",
    "J3",
    "J4",
    "J5",
    "J6",
    "J7",
    "K1",
    "K2",
    "K3",
    "K4",
    "K5",
    "K6",
    "K7",
    "L0",
    "L1",
    "L2",
    "L3",
    "L4",
    "L5",
    "L6",
    "L7",
    "M1",
    "M2",
    "M3",
    "M4",
    "M5",
    "M6",
    "N1",
    "N2",
    "N3",
    "N4",
    "N5",
    "N6",
    "O1",
    "O2",
    "O3",
    "O4",
    "O5",
    "O6",
    "P1",
    "P2",
    "P3",
    "P4",
    "P5",
    "P6",
    "Q1",
    "Q2",
    "Q3",
    "Q4",
    "Q5",
    "Q6",
    "R1",
    "R2",
    "R3",
    "R4",
    "R5",
    "R6",
    "S0",
    "S1",
    "S2",
    "S3"
  ];

  const chunkData = (data, rowsPerPage) => {
    const chunks = [];
    for (let i = 0; i < data.length; i += rowsPerPage) {
      chunks.push(data.slice(i, i + rowsPerPage));
    }
    return chunks;
  };

  const exportFile = async () => {
    try {
      const response = await dispatch(getAllSeatNumberAllocation())
        .unwrap()
        .then(async (res) => {
          let data = [];
          seatData.forEach((seat, index) => {
            const matchedItem = res?.data?.find((item) => item.seatNumber === seat);
            if (matchedItem) {
              data.push({ ...matchedItem, srNo: index + 1 ,seatAllocated:'Yes'});
            } else {
              data.push({
                firstName: '',
                lastName: '',
                id: '',
                seatNumber: seat,
                srNo: index + 1,
                seatAllocated: 'No'
              });
            }
          });
          const chunks = chunkData(data || [], 34);
          console.log("DATA",data)
          setExportData(chunks);
          const workbook = new ExcelJS.Workbook();
          const setAllocationSheet = workbook.addWorksheet(
            "Seat allocating details"
          )

          setAllocationSheet.columns = [
            { header: "Sr No.", key: "srNo", width: 10 },
            { header: "Employee Id", key: "id", width: 20 },
            { header: "First Name", key: "firstName", width: 20 },
            { header: "Last Name", key: "lastName", width: 20 },
            { header: "Seat No", key: "seatNumber", width: 20 },
            { header: "Seat Allocated", key: "seatAllocated", width: 20 },
          ];

          data &&
            data?.length > 0 &&
            data?.forEach((el, index) => {
              const row = setAllocationSheet.addRow({
                srNo: index + 1,
                id: el?.id || "-",
                firstName: el?.firstName || "-",
                lastName: el?.lastName || "-",
                seatNumber: el?.seatNumber || "-",
                seatAllocated: el?.seatAllocated || "-",
              });

              row.eachCell((cell) => {
                cell.alignment = { horizontal: "center", vertical: "center" };
              });
            });;

          setAllocationSheet.getRow(1).eachCell((cell) => {
            cell.alignment = { horizontal: "center", vertical: "center" };
            cell.font = { bold: true };
          });

          // Generate buffer and save file
          const buffer = await workbook.xlsx.writeBuffer();
          const TIME = moment().format("YYYYMMDDHHmmss");
          const FILE_NAME = `Seat_Allocation${TIME}.xlsx`;

          saveAs(new Blob([buffer]), FILE_NAME);
        });
    } catch (error) {
      toast.error(error?.message);
    }
  };
  return (
    <>
      <Container className="Employee-wrapper Allocate-Seat-container" maxWidth="xl" >
        <form onSubmit={handleSubmit} autoComplete="off" style = {{paddingTop : '0px'}}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
    
          >
            <div className="Allocate-Seat-title title-button-wrapper">
              <Typography variant="h4" gutterBottom>
                Seat Allocation
              </Typography>
              <div className='employee-header-right'>
                <div className="export-buttons">
                  <Button
                    variant="contained"
                    onClick={exportFile}
                    // startIcon={<FileUploadSharpIcon />}
                    className="filter-icon-part"
                    title="Export Employees"
                  >
                    <img src="/assets/images/export.svg" />
                    Export as excel
                  </Button>
                </div>
              </div>
            </div>
            <Stack direction="row" alignItems="center">
              {/* <LoadingButton
              loading={isSubmitting}
              type="submit"
              variant="contained"
            >
              Save
            </LoadingButton> */}
              {/* <Button
              variant="contained"
              onClick={()=>navigate(-1)}
              startIcon={<ArrowBackIcon />}
              style={{ marginLeft: "10px" }}
            >
              Back To List
            </Button> */}
            </Stack>
          </Stack>
          <Card className="chips-card" style = {{paddingTop : '0px'}}>
            <Container maxWidth="xl" style = {{paddingTop : '0px'}}>
              <Grid container spacing={3}  style = {{paddingTop : '0px'}}>
                {/* <Grid item xs={4} mb={3}> */}
                {/* <Autocomplete
                size="small"
                fullWidth
                defaultValue=""
                options={employeeData || []}
                key={employeeData || []}
                getOptionLabel={(option) => option?.firstName ?
                  `${option?.firstName} ${option?.lastName}` : ""
                }
                onChange={(event, newValue) => {
                  setFieldValue(
                    `employeeId`,
                    newValue?.id || "",
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Employee"
                    error={Boolean(
                      touched?.employeeId &&
                      errors?.employeeId 
                    )}
                    helperText={
                      touched?.employeeId &&
                      errors?.employeeId
                    }
                  />
                )}
              
                value={employeeData?.find((x) =>
                  x.id === values?.employeeId
                  ) || ""}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                fullWidth
                disabled
                autoComplete="off"
                label="Seat Number"
                name="seatNumber"
                {...getFieldProps("seatNumber")}
                // value={selected.toString()}
                value={values.seatNumber}
                onChange={handleChange}
                variant="outlined"
                size="small"
                error={Boolean(touched.seatNumber && errors.seatNumber)}
                helperText={touched.seatNumber && errors.seatNumber}
              />
            </Grid> */}
                {/* <Grid item xs={6} mb={3}></Grid> */}
                {loading ? (
                  <Loader />
                ) : newRows.length > 0 ? (
                  <Grid item xs={12} mb={2}>
                    <SeatPicker
                      addSeatCallback={addSeatCallback}
                      removeSeatCallback={removeSeatCallback}
                      rows={newRows}
                      // alpha
                      class="seat2"
                      maxReservableSeats={1}
                      // visible
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12} mb={2}>
                    <SeatPicker
                      addSeatCallback={addSeatCallback}
                      removeSeatCallback={removeSeatCallback}
                      rows={rows}
                      // alpha
                      class="seat2"
                      maxReservableSeats={1}
                      // visible
                    />
                  </Grid>
                )}

                {loading ? (
                  <Loader />
                ) : newSpecialRows?.length > 0 ? (
                  <Grid
                  item
                  xs={12}
                  mb={2}
                  className="special_rows"
                >
                  <SeatPicker
                    addSeatCallback={addSeatCallback}
                    removeSeatCallback={removeSeatCallback}
                    rows={newSpecialRows}
                    maxReservableSeats={1}
                  />
                </Grid>
                ) : (
                  <Grid
                  item
                  xs={12}
                  mb={2}
                  className="special_rows"
                >
                  <SeatPicker
                    addSeatCallback={addSeatCallback}
                    removeSeatCallback={removeSeatCallback}
                    rows={specialRows}
                    maxReservableSeats={1}
                  />
                </Grid>
                )}
                {/* <Grid item xs={12} mb={3}>
              <LoadingButton
                loading={isSubmitting}
                type="submit"
                variant="contained"
              >
                Allocate
              </LoadingButton>
            </Grid> */}
                {/* {selected.length !== 0 ? (
              <>
                <div className="seat-price">
                  <div className="seat-select">
                    <h1 className="seats-select">SEAT:{selected.toString()}</h1>
                  </div>
                  <div className="totalprice">
                    <h1 className="price">
                      price:{"$"}
                      {totalprice}
                    </h1>
                  </div>
                </div>
                <button
                  className="continue"
                  onClick={() => navigate(`/Final/${selected}/${totalprice}`)}
                >
                  continue
                </button>
              </>
            ) : null} */}
              </Grid>
            </Container>
          </Card>
          <Modal
            open={open2}
            onClose={handleClose2}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Stack spacing={2} sx={style}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Autocomplete
                    size="small"
                    fullWidth
                    defaultValue=""
                    options={finalEmployees ? finalEmployees : employeeData}
                    key={employeeData || []}
                    getOptionLabel={(option) =>
                      option?.firstName
                        ? `${option?.firstName} ${option?.lastName}`
                        : ""
                    }
                    renderOption={(props, option) => (
                      <li {...props} value={option.id} key={option.id}>
                        {`${option.firstName} ${option.lastName}` || ""}
                      </li>
                    )}
                    onChange={(e, newValue) => {
                      setFieldValue(`employeeId`, newValue?.id || "");
                      if (newValue) {
                        setNoteError({ flag: false, title: "" });
                      }
                      if (newValue == null) {
                        setNoteError({
                          flag: true,
                          title: "Please select employee",
                        });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Employee"
                        helperText={noteError?.title && noteError?.title}
                        error={Boolean(noteError?.flag && noteError?.flag)}
                      />
                    )}
                    value={
                      employeeData?.find((x) => x.id === values?.employeeId) ||
                      ""
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <div style={{ display: "flex" }}>
                    <Button
                      variant="contained"
                      onClick={handleRemoveAllocate}
                      sx={{
                        fontSize: "13px",
                        marginRight: "10px",
                        marginTop: "10px",
                      }}
                    >
                      Remove Allocate
                    </Button>
                    {/* <Stack direction="row" alignItems="right" justifyContent="flex-end"> */}
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                      onClick={() =>
                        handleReAllocate(values.employeeId, values?.seatNumber)
                      }
                      sx={{
                        marginRight: "10px",
                        fontSize: "13px",
                        marginTop: "auto",
                        height: "35px",
                      }}
                    >
                      Reallocate
                    </LoadingButton>
                    <Button
                      variant="contained"
                      onClick={handleCancel2}
                      sx={{
                        fontSize: "13px",
                        marginTop: "auto",
                        height: "35px",
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Stack>
          </Modal>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Stack spacing={2} sx={style}>
              <Autocomplete
                size="small"
                fullWidth
                defaultValue=""
                options={finalEmployees ? finalEmployees : employeeData}
                key={employeeData || []}
                getOptionLabel={(option) =>
                  option?.firstName
                    ? `${option?.firstName} ${option?.lastName}`
                    : ""
                }
                renderOption={(props, option) => (
                  <li {...props} value={option.id} key={option.id}>
                    {`${option.firstName} ${option.lastName}` || ""}
                  </li>
                )}
                onChange={(e, newValue) => {
                  setFieldValue(`employeeId`, newValue?.id || "");
                  if (newValue) {
                    setNoteError({ flag: false, title: "" });
                  }
                  if (newValue == null) {
                    setNoteError({
                      flag: true,
                      title: "Please select employee",
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Employee"
                    helperText={noteError?.title && noteError?.title}
                    error={Boolean(noteError?.flag && noteError?.flag)}
                  />
                )}
                value={
                  employeeData?.find((x) => x.id === values?.employeeId) || ""
                }
              />
              <Stack
                direction="row"
                alignItems="right"
                justifyContent="flex-end"
              >
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  onClick={() =>
                    handleAllocate(values.employeeId, values?.seatNumber)
                  }
                  sx={{ marginRight: "15px" }}
                >
                  Allocate
                </LoadingButton>
                <Button variant="contained" onClick={handleCancel}>
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </Modal>
        </form>
      </Container>
    </>
  );
}

const componentConfig = [
  {
    component: SeatAllocation,
    path: "/seatAllocation",
    public: false,
    layout: DashboardLayout,
    group: "Asset Management",
    sidebar: true,
    role: ["admin"],
  },
];

export default componentConfig;
